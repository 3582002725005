:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    background:rgb(35,35,35);
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}

@font-face {
    font-family: 'LegionFont';
    src: local('LegionFont'), url(./assets/fonts/BungeeHairline-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  .content {
    top: 50%;
    left: 50%;
    overflow: hidden;
    font-family: 'LegionFont';
    font-size: 12px;
    color: #ecf0f1;
}
.content__container {
    font-weight: 600;
    overflow: hidden;
    height: 30px;
}
.content__container:before {
    content: '[';
    left: 0;
}
.content__container:after {
    content: ']';
    position: absolute;
    right: 0;
}
.content__container:after, .content__container:before {
    position: absolute;
    top: 0;
    color: #16a085;
    font-size: 42px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.content__container__text {
    display: inline;
    float: left;
    margin: 0;
}
.content__container__list {
    margin-top: 0;
    padding-left: 110px;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
.content__container__list__item {
    line-height: 40px;
    margin: 0;
}
@-webkit-keyframes opacity {
    0%, 100% {
        opacity: 0;
   }
    50% {
        opacity: 1;
   }
}
@-webkit-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
   }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
   }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
   }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
   }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
   }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
   }
}
@-o-keyframes opacity {
    0%, 100% {
        opacity: 0;
   }
    50% {
        opacity: 1;
   }
}
@-o-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
   }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
   }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
   }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
   }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
   }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
   }
}
@-moz-keyframes opacity {
    0%, 100% {
        opacity: 0;
   }
    50% {
        opacity: 1;
   }
}
@-moz-keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
   }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
   }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
   }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
   }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
   }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
   }
}
@keyframes opacity {
    0%, 100% {
        opacity: 0;
   }
    50% {
        opacity: 1;
   }
}
@keyframes change {
    0%, 12.66%, 100% {
        transform: translate3d(0, 0, 0);
   }
    16.66%, 29.32% {
        transform: translate3d(0, -25%, 0);
   }
    33.32%, 45.98% {
        transform: translate3d(0, -50%, 0);
   }
    49.98%, 62.64% {
        transform: translate3d(0, -75%, 0);
   }
    66.64%, 79.3% {
        transform: translate3d(0, -50%, 0);
   }
    83.3%, 95.96% {
        transform: translate3d(0, -25%, 0);
   }
}
